<template>
    <v-container fluid class="pa-0">
        <page-title
                :heading="$t('erp.lang_ware_storageoverview_create')"
                :subheading="$t('erp.lang_ware_storageoverview_create')"
                :icon="icon"
                show-previous-button
        ></page-title>

        <div class="app-main__inner">
            <storage-cerate-componet />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import StorageCerateComponet from "../../../../components/erp/storage/storageoverview/StorageCerateComponet";


    //configs
    export default {
        components: {
            StorageCerateComponet,
            PageTitle
        },
        data: () => ({
            icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
        }),


    };
</script>
