<template>

    <div>

        <v-autocomplete :clearable="this.clearable" :color="this.color" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :dense="this.dense" :items="this.countries" :label="this.label"
                        :outlined="this.outlined"
                        :prepend-inner-icon="this.icon"
                        :rules="this.rules"
                        @focus="showTouchKeyboard"
                        item-text="name"
                        item-value="id"
                        v-model="county"
        >

            <template v-slot:selection="data">
                <v-chip
                        :input-value="data.selected"
                        @click="data.select"
                        color="white"

                        v-bind="data.attrs"
                >
                    <v-avatar left>
                        <country-flag :country="data.item.id.toLowerCase()" size="small"/>
                    </v-avatar>
                    {{ data.item.name }}
                </v-chip>
            </template>

            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-avatar>
                        <country-flag :country="data.item.id.toLowerCase()"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard
                    :accept="hideTouchKeyboard"
                    :cancel="hideTouchKeyboard"
                    :defaultKeySet="touchKeyboard.keySet"
                    :input="touchKeyboard.input"
                    :layout="touchKeyboard.layout"
                    :options="touchKeyboard.options"
                    class="internalWidthExpanded"
                    id="onScreenKeyboard"
                    v-if="touchKeyboard.visible"
            />
        </div>
    </div>
</template>

<script>
    import CountryFlag from 'vue-country-flag'
    import mixin from "../../mixins/KeyboardMixIns";


    export default {
        name: "country",
        mixins: [mixin],
        components: {
            CountryFlag
        },
        props: {
            icon: String,
            outlined: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'primary'
            },
            rules: Array,
            label: {
                type: String,
                default: "Nationality"
            },
            code: {
                type: String,
                default: ""
            }
        },
        mounted() {
            this.country = this.code;
            this.$emit('input', this.country);
        },
        methods: {},
        data: () => {
            return {
                countriesOBJ: {
                    "AF": "Afghanistan",
                    "AX": "Åland Islands",
                    "AL": "Albania",
                    "DZ": "Algeria",
                    "AS": "American Samoa",
                    "AD": "Andorra",
                    "AO": "Angola",
                    "AI": "Anguilla",
                    "AQ": "Antarctica",
                    "AG": "Antigua and Barbuda",
                    "AR": "Argentina",
                    "AM": "Armenia",
                    "AW": "Aruba",
                    "AU": "Australia",
                    "AT": "Austria",
                    "AZ": "Azerbaijan",
                    "BS": "Bahamas",
                    "BH": "Bahrain",
                    "BD": "Bangladesh",
                    "BB": "Barbados",
                    "BY": "Belarus",
                    "BE": "Belgium",
                    "BZ": "Belize",
                    "BJ": "Benin",
                    "BM": "Bermuda",
                    "BT": "Bhutan",
                    "BO": "Bolivia, Plurinational State of",
                    "BQ": "Bonaire, Sint Eustatius and Saba",
                    "BA": "Bosnia and Herzegovina",
                    "BW": "Botswana",
                    "BV": "Bouvet Island",
                    "BR": "Brazil",
                    "IO": "British Indian Ocean Territory",
                    "BN": "Brunei Darussalam",
                    "BG": "Bulgaria",
                    "BF": "Burkina Faso",
                    "BI": "Burundi",
                    "KH": "Cambodia",
                    "CM": "Cameroon",
                    "CA": "Canada",
                    "CV": "Cape Verde",
                    "KY": "Cayman Islands",
                    "CF": "Central African Republic",
                    "TD": "Chad",
                    "CL": "Chile",
                    "CN": "China",
                    "CX": "Christmas Island",
                    "CC": "Cocos (Keeling) Islands",
                    "CO": "Colombia",
                    "KM": "Comoros",
                    "CG": "Congo",
                    "CD": "Congo, the Democratic Republic of the",
                    "CK": "Cook Islands",
                    "CR": "Costa Rica",
                    "CI": "Côte d'Ivoire",
                    "HR": "Croatia",
                    "CU": "Cuba",
                    "CW": "Curaçao",
                    "CY": "Cyprus",
                    "CZ": "Czech Republic",
                    "DK": "Denmark",
                    "DJ": "Djibouti",
                    "DM": "Dominica",
                    "DO": "Dominican Republic",
                    "EC": "Ecuador",
                    "EG": "Egypt",
                    "SV": "El Salvador",
                    "GQ": "Equatorial Guinea",
                    "ER": "Eritrea",
                    "EE": "Estonia",
                    "ET": "Ethiopia",
                    "FK": "Falkland Islands (Malvinas)",
                    "FO": "Faroe Islands",
                    "FJ": "Fiji",
                    "FI": "Finland",
                    "FR": "France",
                    "GF": "French Guiana",
                    "PF": "French Polynesia",
                    "TF": "French Southern Territories",
                    "GA": "Gabon",
                    "GM": "Gambia",
                    "GE": "Georgia",
                    "DE": "Germany",
                    "GH": "Ghana",
                    "GI": "Gibraltar",
                    "GR": "Greece",
                    "GL": "Greenland",
                    "GD": "Grenada",
                    "GP": "Guadeloupe",
                    "GU": "Guam",
                    "GT": "Guatemala",
                    "GG": "Guernsey",
                    "GN": "Guinea",
                    "GW": "Guinea-Bissau",
                    "GY": "Guyana",
                    "HT": "Haiti",
                    "HM": "Heard Island and McDonald Islands",
                    "VA": "Holy See (Vatican City State)",
                    "HN": "Honduras",
                    "HK": "Hong Kong",
                    "HU": "Hungary",
                    "IS": "Iceland",
                    "IN": "India",
                    "ID": "Indonesia",
                    "IR": "Iran, Islamic Republic of",
                    "IQ": "Iraq",
                    "IE": "Ireland",
                    "IM": "Isle of Man",
                    "IL": "Israel",
                    "IT": "Italy",
                    "JM": "Jamaica",
                    "JP": "Japan",
                    "JE": "Jersey",
                    "JO": "Jordan",
                    "KZ": "Kazakhstan",
                    "KE": "Kenya",
                    "KI": "Kiribati",
                    "KP": "Korea, Democratic People's Republic of",
                    "KR": "Korea, Republic of",
                    "KW": "Kuwait",
                    "KG": "Kyrgyzstan",
                    "LA": "Lao People's Democratic Republic",
                    "LV": "Latvia",
                    "LB": "Lebanon",
                    "LS": "Lesotho",
                    "LR": "Liberia",
                    "LY": "Libya",
                    "LI": "Liechtenstein",
                    "LT": "Lithuania",
                    "LU": "Luxembourg",
                    "MO": "Macao",
                    "MK": "Macedonia, the Former Yugoslav Republic of",
                    "MG": "Madagascar",
                    "MW": "Malawi",
                    "MY": "Malaysia",
                    "MV": "Maldives",
                    "ML": "Mali",
                    "MT": "Malta",
                    "MH": "Marshall Islands",
                    "MQ": "Martinique",
                    "MR": "Mauritania",
                    "MU": "Mauritius",
                    "YT": "Mayotte",
                    "MX": "Mexico",
                    "FM": "Micronesia, Federated States of",
                    "MD": "Moldova, Republic of",
                    "MC": "Monaco",
                    "MN": "Mongolia",
                    "ME": "Montenegro",
                    "MS": "Montserrat",
                    "MA": "Morocco",
                    "MZ": "Mozambique",
                    "MM": "Myanmar",
                    "NA": "Namibia",
                    "NR": "Nauru",
                    "NP": "Nepal",
                    "NL": "Netherlands",
                    "NC": "New Caledonia",
                    "NZ": "New Zealand",
                    "NI": "Nicaragua",
                    "NE": "Niger",
                    "NG": "Nigeria",
                    "NU": "Niue",
                    "NF": "Norfolk Island",
                    "MP": "Northern Mariana Islands",
                    "NO": "Norway",
                    "OM": "Oman",
                    "PK": "Pakistan",
                    "PW": "Palau",
                    "PS": "Palestine, State of",
                    "PA": "Panama",
                    "PG": "Papua New Guinea",
                    "PY": "Paraguay",
                    "PE": "Peru",
                    "PH": "Philippines",
                    "PN": "Pitcairn",
                    "PL": "Poland",
                    "PT": "Portugal",
                    "PR": "Puerto Rico",
                    "QA": "Qatar",
                    "RE": "Réunion",
                    "RO": "Romania",
                    "RU": "Russian Federation",
                    "RW": "Rwanda",
                    "BL": "Saint Barthélemy",
                    "SH": "Saint Helena, Ascension and Tristan da Cunha",
                    "KN": "Saint Kitts and Nevis",
                    "LC": "Saint Lucia",
                    "MF": "Saint Martin (French part)",
                    "PM": "Saint Pierre and Miquelon",
                    "VC": "Saint Vincent and the Grenadines",
                    "WS": "Samoa",
                    "SM": "San Marino",
                    "ST": "Sao Tome and Principe",
                    "SA": "Saudi Arabia",
                    "SN": "Senegal",
                    "RS": "Serbia",
                    "SC": "Seychelles",
                    "SL": "Sierra Leone",
                    "SG": "Singapore",
                    "SX": "Sint Maarten (Dutch part)",
                    "SK": "Slovakia",
                    "SI": "Slovenia",
                    "SB": "Solomon Islands",
                    "SO": "Somalia",
                    "ZA": "South Africa",
                    "GS": "South Georgia and the South Sandwich Islands",
                    "SS": "South Sudan",
                    "ES": "Spain",
                    "LK": "Sri Lanka",
                    "SD": "Sudan",
                    "SR": "Suriname",
                    "SJ": "Svalbard and Jan Mayen",
                    "SZ": "Swaziland",
                    "SE": "Sweden",
                    "CH": "Switzerland",
                    "SY": "Syrian Arab Republic",
                    "TW": "Taiwan, Province of China",
                    "TJ": "Tajikistan",
                    "TZ": "Tanzania, United Republic of",
                    "TH": "Thailand",
                    "TL": "Timor-Leste",
                    "TG": "Togo",
                    "TK": "Tokelau",
                    "TO": "Tonga",
                    "TT": "Trinidad and Tobago",
                    "TN": "Tunisia",
                    "TR": "Turkey",
                    "TM": "Turkmenistan",
                    "TC": "Turks and Caicos Islands",
                    "TV": "Tuvalu",
                    "UG": "Uganda",
                    "UA": "Ukraine",
                    "AE": "United Arab Emirates",
                    "GB": "United Kingdom",
                    "US": "United States",
                    "UM": "United States Minor Outlying Islands",
                    "UY": "Uruguay",
                    "UZ": "Uzbekistan",
                    "VU": "Vanuatu",
                    "VE": "Venezuela, Bolivarian Republic of",
                    "VN": "Viet Nam",
                    "VG": "Virgin Islands, British",
                    "VI": "Virgin Islands, U.S.",
                    "WF": "Wallis and Futuna",
                    "EH": "Western Sahara",
                    "YE": "Yemen",
                    "ZM": "Zambia",
                    "ZW": "Zimbabwe"
                }
            }
        },
        computed: {
            county: {
                get() {
                    return this.code
                },
                set(val) {
                    this.$emit('input', val);
                }
            },
            countries: function () {
                let c = [];
                for (let key in this.countriesOBJ)
                    c.push({
                        id: key,
                        name: this.countriesOBJ[key]
                    });
                return c;
            }
        }
    }
</script>

<style scoped>

</style>